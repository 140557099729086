import { FC } from 'react'

import { routes } from 'utils/routes'
import { Link } from 'components/links'
import { MarketModel } from 'api/nest'

import classes from './tooltip.module.css'
import { SelectMarketHandler } from '../StatesMap'

interface SingleTooltipProps {
  linkToMarket?: boolean
  market: MarketModel
  hoverEffects?: boolean
  onSelectMarket?: SelectMarketHandler
}

export const SingleTooltip: FC<SingleTooltipProps> = ({
  linkToMarket,
  market,
  hoverEffects,
  onSelectMarket,
}) => {
  const onClickHandler = (e: any) => {
    if (onSelectMarket) {
      onSelectMarket(market, e)
    }
  }

  return linkToMarket ? (
    <Link
      href={routes.market(market.slug)}
      theme="primary"
      className={hoverEffects ? classes.homePageLink : classes.link}
      onClick={onClickHandler}
    >
      {market.name}
    </Link>
  ) : (
    <span className={hoverEffects ? classes.homePageLink : classes.link}>{market.name}</span>
  )
}
