import { FC } from 'react'

import { MarketModel } from 'api/nest'
import { Link } from 'components/links'
import { routes } from 'utils/routes'

import classes from './tooltip.module.css'
import { SelectMarketHandler } from '../StatesMap'

interface GroupTooltipProps {
  linkToMarket?: boolean
  markets: MarketModel[]
  hoverEffects?: boolean
  onSelectMarket?: SelectMarketHandler
}

export const GroupTooltip: FC<GroupTooltipProps> = ({
  linkToMarket,
  markets,
  hoverEffects,
  onSelectMarket,
}) => {
  const onClickHandler = (e: any, market: MarketModel) => {
    if (onSelectMarket) {
      onSelectMarket(market, e)
    }
  }

  return (
    <ul className={classes.list}>
      {markets.map((market) => (
        <li key={market.uuid} className={classes.listItem}>
          {linkToMarket ? (
            <Link
              href={routes.market(market.slug)}
              theme="primary"
              className={hoverEffects ? classes.homePageLink : classes.link}
              onClick={(e) => onClickHandler(e, market)}
            >
              {market.name}
            </Link>
          ) : (
            <span className={hoverEffects ? classes.homePageLink : classes.link}>
              {market.name}
            </span>
          )}
        </li>
      ))}
    </ul>
  )
}
