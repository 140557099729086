import { FormNameType } from 'utils//types'
import { yup } from 'components/form/yup'
import { EMAIL_RULE, FIRST_NAME_RULE, LAST_NAME_RULE, ZIP_CODE_RULE } from 'components/form-field'

import { ISubscribeForm } from './types'

export const FORM_FIELD: FormNameType<ISubscribeForm> = {
  email: 'email',
  firstName: 'firstName',
  postalCode: 'postalCode',
  lastName: 'lastName',
}

export const FORM_VALIDATOR = yup.object().shape({
  [FORM_FIELD.email]: EMAIL_RULE,
  [FORM_FIELD.firstName]: FIRST_NAME_RULE.required(),
  [FORM_FIELD.lastName]: LAST_NAME_RULE.required(),
  [FORM_FIELD.postalCode]: ZIP_CODE_RULE.required(),
})
