import React, { FC } from 'react'

import { percentOfNumber } from 'utils/number'
import { MarketModel } from 'api/nest'

import classes from './statesMap.module.css'
import { MAP_VIEW_BOX_WIDTH, TOOLTIP_ID } from './consts'
import Pin from './mapPin.svg?url'
import { SelectMarketHandler } from './StatesMap'

export interface IMapPinProps {
  x: number
  y: number
  markets: MarketModel[]
  index: number
  hoverEffects?: boolean
  onSelectMarket?: SelectMarketHandler
}

const LEFT_MAP_PATH = percentOfNumber(MAP_VIEW_BOX_WIDTH, 60)

export const MapPin: FC<IMapPinProps> = ({
  y,
  x,
  markets,
  index,
  hoverEffects,
  onSelectMarket,
}) => {
  const isGroup = markets.length > 1
  const onClick = (e) => {
    const market = isGroup ? undefined : markets[0]

    if (onSelectMarket) {
      market && onSelectMarket(market, e)
      return
    }
  }

  return (
    <image
      href={Pin}
      x={x}
      y={y}
      onClick={onClick}
      className={hoverEffects ? classes.homePageMapPin : classes.mapPin}
      style={{ '--transition-delay': `${index * 0.1}s` } as React.CSSProperties}
      data-is-group={isGroup}
      data-tip={index}
      data-for={TOOLTIP_ID}
      width={40}
      height={48}
      data-place={x >= LEFT_MAP_PATH ? 'left' : undefined}
    />
  )
}
