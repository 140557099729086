import Head from 'next/head'

import loadingAnimation from './loading.gif'

//NOTE: We should use it because size of loading animation is large even after optimization. Without it
// loading animation doesn't show when search modal opens first because it can't load fast.
export function PreloadLoadingAnimation() {
  return (
    <Head>
      <link rel="preload" as="image" href={loadingAnimation.src} />
    </Head>
  )
}
