import NextImage from 'next/image'
import { useTimeoutFn } from 'react-use'

import { Heading, HeadingProps } from 'components/heading'

import classes from './waiting-content.module.css'
import loadingImage from './loading.gif'

interface WaitingContentProps {
  HeadingProps?: Omit<Partial<HeadingProps>, 'ref'>
  onDoneTimer?: () => void
}
export const WaitingContent = ({ HeadingProps = {}, onDoneTimer }: WaitingContentProps) => {
  //NOTE: We should show force loading animation
  useTimeoutFn(() => {
    if (typeof onDoneTimer === 'function') onDoneTimer()
  }, 2000)

  return (
    <div className={classes.container}>
      <Heading theme="h2" component="h3" className={classes.title} {...HeadingProps}>
        Checking your area...
      </Heading>
      <p className={classes.text}>This should only take a moment.</p>
      <NextImage
        priority
        src={loadingImage}
        alt="loading..."
        unoptimized
        width={427}
        height={258}
        className={classes.image}
      />
    </div>
  )
}
