import { TooltipProps } from 'react-tooltip'

import { MarketModel, MarketSlug } from 'api/nest'

import { MARKETS_COORDINATES } from './consts'
import { IMapPinProps } from './MapPin'

type MarketListBySlug = Record<MarketSlug, MarketModel | undefined>

const getMarketListBySlug = (markets: MarketModel[]) => {
  return markets.reduce((acc, market) => {
    acc[market.slug] = market
    return acc
  }, {} as MarketListBySlug)
}

export const getPinsByMarketList = (markets: MarketModel[]) => {
  const marketListBySlug = getMarketListBySlug(markets)

  return MARKETS_COORDINATES.reduce((acc, { marketSlugs, y, x }) => {
    const marketsBySlug = marketSlugs.reduce((acc, slug) => {
      const market = marketListBySlug[slug]
      if (market) {
        acc.push(market)
      }
      return acc
    }, [] as MarketModel[])

    if (marketsBySlug.length) {
      acc.push({
        markets: marketsBySlug,
        y,
        x,
      })
    }

    return acc
  }, [] as Omit<IMapPinProps, 'index'>[])
}

export const overrideTooltipPosition: TooltipProps['overridePosition'] = (
  { top, left },
  currentEvent,
  currentTarget,
  refNode,
  place,
) => {
  const OFFSET_DIFFERENCE_BY_DESIGN = 7
  let newLeft = left
  let newTop = top

  const rect = (currentTarget as HTMLElement).getBoundingClientRect()

  if (place === 'left') {
    newLeft = left + OFFSET_DIFFERENCE_BY_DESIGN
    newTop = Math.max(top, rect.top)
  } else if (place === 'right') {
    newLeft = left - OFFSET_DIFFERENCE_BY_DESIGN
    newTop = Math.max(top, rect.top)
  }

  if (place === 'top') {
    newTop = top + OFFSET_DIFFERENCE_BY_DESIGN
  } else if (place === 'bottom') {
    newTop = top - OFFSET_DIFFERENCE_BY_DESIGN
  }

  return {
    top: newTop,
    left: newLeft,
  }
}
