import { useMutation } from '@tanstack/react-query'
import { SubmitHandler } from 'react-hook-form'
import { FC, useEffect } from 'react'
import { toast } from 'react-toastify'
import { useQuery } from '@tanstack/react-query'
import { usePathname } from 'next/navigation'

import { nest } from 'api'
import { Button } from 'components/button'
import { withRouterDialog } from 'components/dialog'
import {
  EmailFormField,
  FirstNameFormField,
  FORM_FIELD_LABEL,
  LastNameFormField,
  ZipCodeFormField,
} from 'components/form-field'
import { Form } from 'components/form/Form'
import { useForm } from 'components/form'
import { SubmitFormButton } from 'components/submit-form-button'
import { Heading } from 'components/heading'
import { useDialogContext } from 'components/dialog/utils'
import { DIALOG_NAME } from 'const/dialogs'
import { routes } from 'utils/routes'

import { ISubscribeForm } from './types'
import { FORM_FIELD, FORM_VALIDATOR } from './consts'
import classes from './not-available-dialog.module.css'

interface NotAvailableContentProps {
  searchZipCode: string
}

export const NotAvailableContent: FC<NotAvailableContentProps> = ({ searchZipCode }) => {
  const { close } = useDialogContext()
  const pathname = usePathname()

  const isPartnerPortalPage = pathname?.startsWith(routes.partnerPortal)

  const { data } = useQuery([searchZipCode], () => nest.auth.me(), {
    refetchInterval: false,
    refetchOnMount: true,
    refetchOnWindowFocus: false,
    enabled: isPartnerPortalPage,
  })

  const formMethods = useForm<ISubscribeForm>(FORM_VALIDATOR, {
    defaultValues: {
      [FORM_FIELD.postalCode]: searchZipCode,
    },
  })

  useEffect(() => {
    if (data && isPartnerPortalPage) {
      // setting in formMethods defaultValues does not work in edge case - when clearing cache and hard page reloading
      formMethods.setValue(FORM_FIELD.firstName, data?.broker?.firstName || '')
      formMethods.setValue(FORM_FIELD.lastName, data?.broker?.lastName || '')
      formMethods.setValue(FORM_FIELD.email, data?.broker?.email || '')
    }
  }, [data, formMethods, isPartnerPortalPage])

  const createWaitlistEntryMutation = useMutation({
    mutationFn: nest.waitlist.createWaitlistEntry,
  })

  //TODO: add subscribe request
  const submitForm: SubmitHandler<ISubscribeForm> = async (formData) => {
    await createWaitlistEntryMutation.mutateAsync(formData)
    close()
    toast.success('We’ll be in touch!')
  }

  return (
    <div className={classes.container}>
      <p>
        Currently, Home Price Protection is not available in your area.
        <br />
        But don’t worry — we’re constantly expanding to new markets.
      </p>

      <Heading theme="h5" component="h5" className={classes.formTitle}>
        Sign up to receive a notification when we add your area.
      </Heading>

      <Form onSubmit={submitForm} formMethods={formMethods}>
        <div className={classes.fields}>
          <FirstNameFormField
            name={FORM_FIELD.firstName}
            label={'Your ' + FORM_FIELD_LABEL.firstName}
            fullWidth
          />
          <LastNameFormField
            name={FORM_FIELD.lastName}
            label={'Your ' + FORM_FIELD_LABEL.lastName}
            fullWidth
          />
          <EmailFormField
            name={FORM_FIELD.email}
            label={'Your ' + FORM_FIELD_LABEL.email}
            fullWidth
          />
          <ZipCodeFormField
            name={FORM_FIELD.postalCode}
            fullWidth
            label={'Your ' + FORM_FIELD_LABEL.zipCode}
            placeholder=""
          />
        </div>
        <div className={classes.buttons}>
          <Button onClick={close} className={classes.button} theme="secondary">
            No, Thanks
          </Button>
          <SubmitFormButton className={classes.button}>Get notified</SubmitFormButton>
        </div>
      </Form>
    </div>
  )
}

export const NotAvailableDialog = withRouterDialog(NotAvailableContent, {
  modalName: DIALOG_NAME.marketNotAvailable,
  theme: 'onlyContent',
  withoutHeader: true,
})
